/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $(document).foundation(); // Foundation JavaScript

        //init webnerds functions
        stickyHeader();
        mobileNav();
        autoScrollToAnchor();
        siteHeaderSubMenu();
        questionsAndAnswers();
        gallery();
        instagramViewer();
        // initSearch();
        ajaxSearch();
        atelierModal();
        footerScrollToTop();
        disableScrollStateFor('.l-x');
        videoOnScroll();
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        scrollStateFadeElements();
      }
    },
    'philosophy': {
      init: function() {
        initSlickCarousel();
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        setupVideoClickHandler();
      }
    },
    'home': {
      init: function() {
        initSlickCarousel();
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        setupVideoClickHandler();
      }
    },
    'blog': {
      init: function() {
        $('.preamble-container p:has(img)').css('padding', 0);
        $('.content-container p:has(img)').css('padding', 0);
        initEmailSignup();
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'single': {
      init: function() {
        $commentSection = $('.comments');
        $commentArea = $('#comment');
        var showComment = function(){
          $commentSection.show();
          var offsetTop = 0;
          if ($commentArea.length){
            offsetTop = $commentArea.offset().top;
            $('html,body').animate({ scrollTop:offsetTop }, {
              duration: 350,
              complete: function(){
                $commentArea.focus();
              }
            });
          }
        };
        if (window.location.hash && window.location.hash === '#respond'){
          showComment();
        }
        $('.comment-button').click(function(e){
          showComment();
        });

        $('.preamble-container p:has(img)').css('padding', 0);
        $('.content-container p:has(img)').css('padding', 0);
      },
      finalize: function() {
        setupVideoClickHandler();
      }
    },
    'people': {
      init: function() {
        $(window).resize(function(){
          $(".person-info").css({'opacity':''});
        });
        $(".person-hoverable").click(function(e){
          /**
           * Make object clickable only on s & m screens
           */
          if ($('.size-checker.s-m').css('display') === 'block'){
            var toggleId = e.currentTarget.attributes['data-toggle-id'].nodeValue;
            var opacity = $("#"+toggleId+".person-info").css('opacity');
            $(".person-info").css({'opacity': ''});
            if (opacity < 1){
              $("#"+toggleId+".person-info").css({'opacity': 1, 'visibility' : 'visible'});
            // } else {
            //   $("#"+toggleId+".person-info").css({'opacity': 0});
            }
          }
        });
        var hash = window.location.hash;
        if (hash !== "" && $(hash).length){
          $("html, body").animate({ scrollTop: $(hash).offset().top }, 400);
        }
      }
    },
    'single_webnerds_cpt_work': {
      init: function() {
          initWorkCarousel();
      }
    },
    'single_webnerds_cpt_article': {
      init: function() {
          initArticleCarousel();
      }
    },
    'atelier': {
      init: function() {
        // Set height for elements with plain background
        var $items = $('.flexible-content .atelier-list .background-color');
        for (var i = $items.length - 1; i >= 0; i--){
          $item = $($items[i]);
          var childOuterHeight = $item.find('.text').outerHeight();
          $item.height(childOuterHeight);
        }

        Foundation.onImagesLoaded($('.image img'), function(){
            setTimeout(function() {
              atelierFlow();
            }, 500);
          console.log('Image Loaded! :)');
        });


        $('.flexible-content .atelier-list .atelier-item').click(function(e){
          var hasModal = $(e.currentTarget).hasClass('has-modal');
          if (hasModal){
            var id = e.currentTarget.attributes.dataId.nodeValue;
            openModal(id);
          }
        });
      }
    },
    finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
