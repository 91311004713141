(function($) {
  instagramViewer = function() {
    $instagramViewers = $('.instagram-viewer-js');
    $.each($instagramViewers, function(index, value) {
        var target = $(value).attr('id');
        var accessToken = $(value).data('access-token');
        var userId = $(value).data('user-id');
        var limit = $(value).data('limit');

        console.log(target,accessToken,userId,limit);

        var feed = new Instafeed({
            get: 'user',
            userId: userId,
            accessToken: accessToken,
            target: target,
            resolution: 'standard_resolution',
            limit: limit,
            filter: function(image) {
              var MAX_LENGTH = 200;

              // here we create a property called "short_caption"
              // on the image object, using the original caption
              if (image.caption && image.caption.text && image.caption.text.length > MAX_LENGTH) {
                image.short_caption = image.caption.text.slice(0, MAX_LENGTH) + "...";
              } else if(image.caption && image.caption.text){
                image.short_caption = image.caption.text;
              }else {
                image.short_caption = "";
              }

              // ensure the filter doesn't reject any images
              return true;
            },
            template: '<div class="instagram-post"><img src="{{image}}" /><div class="caption">{{model.short_caption}}</div><a href="{{link}}" class="link" target="_blank"></a></div>'
        });
        feed.run();
    });
    
  };
})(jQuery);
