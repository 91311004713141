(function($) {
	var $body = $('body');

	var $docEl = $('html, body'),
	  $wrap = $('.container.wrap > .content'),
	  scrollTop;

	$.lockBody = function() {
	  if(window.pageYOffset) {
	    scrollTop = window.pageYOffset;

	    $wrap.css({
	      top: - (scrollTop)
	    });
	  }

	  $docEl.css({
	    height: "100%",
	    overflow: "hidden"
	  });
  };

	$.unlockBody = function() {
	  $docEl.css({
	    height: "",
	    overflow: ""
	  });

	  $wrap.css({
	    top: ''
	  });

	  window.scrollTo(0, scrollTop);
	  window.setTimeout(function () {
	    scrollTop = null;
	  }, 0);
  	};

	var _hamburgerCloseHandler = function(cb){
		var isAtelierOpen = $body.hasClass('atelier-open');
		var isSearchOpen = $body.hasClass('search-open');
		var isOverlayOpen = $body.hasClass('overlay-open');
		if (isAtelierOpen){
			closeAtelier();
			stopAtelierModalVideo();
			if (window.animationHandle){
				window.animationHandle.start();
			}
			$body.removeClass('atelier-open');
			if (!isSearchOpen && isOverlayOpen){
				$body.removeClass('overlay-open');
			}
			if (cb){
				cb();
			}
			setupHamburgerClickHandler();
			return;
		}
		if (isSearchOpen){
			doCloseSearchWindow();
			setupHamburgerClickHandler();
			return;
		}
		var isMobileNavOpen = $body.hasClass('mobile-nav-open');
		if (isMobileNavOpen){
			$body.removeClass('mobile-nav-open');
			$body.removeClass('overlay-open');
			setupHamburgerClickHandler();
			$.unlockBody();
			return;
		}
		if (isSearchOpen){
			$body.removeClass('overlay-open');
			setupHamburgerClickHandler();
			return;
		}
		$body.addClass('mobile-nav-open');
		$body.addClass('overlay-open');
		$.lockBody();
	};
	setupHamburgerClickHandler = function(cb){
		var $hamburger = $('.site-header .mobile-nav-toggle');
		$hamburger.off('click');
		$hamburger.click(function(){
			_hamburgerCloseHandler(cb);
		});
	};
	mobileNav = function(){
		setupHamburgerClickHandler();
		// $(window).resize(function() {
		// 	// $body.removeClass('mobile-nav-open');
		// 	// $body.removeClass('overlay-open');
		// });
	};
    $(document).keyup(function(e){
      if (e.keyCode === 27){ // Esc
        _hamburgerCloseHandler();
      }
    });
})(jQuery);
