(function($) {
	/**
	 * Enables .scroll-state effect only for s & m screen sizes
	 * @param  string c Which c to disable. E.g. 'l', 'l-x'
	 */
	disableScrollStateFor = function(c){
		if ($('.size-checker' + c).css('display') === 'block'){
			$('.scroll-state').removeClass('scroll-state');
		}
	};
	scrollStateFadeElements = function(){
		var oTop;
		var oBottom;
		var wWidth = $(window).width();
		var wHeight = $(window).height();

		if (wHeight >= 670  && wWidth <= 599) {
			oTop = 150;
			oBottom = 300;
		} else if (wHeight >= 400 && wWidth <= 599) {
			oTop = 100;
			oBottom = 100;
		} else if (wHeight >= 800 && wWidth >= 760) {
			oTop = 150;
			oBottom = 300;
		} else if (wHeight >= 670  && wWidth <= 759) {
			oTop = 100;
			oBottom = 150;
		} else {
			oTop = 0;
			oBottom = 0;
		}

		var sr = new ScrollReveal({
			easing:'ease-in-out',
			reset:true,
			scale:1,
			viewFactor: 0.9,
			viewOffset: {top: oTop, right: 0, bottom: oBottom, left: 0}
		});
		sr.reveal('.scroll-state');

	};
	footerScrollToTop = function() {
		$('#site-footer .arrow').click(function(){
			$('html,body').animate({ scrollTop:0 });
		});
	};
	autoScrollToAnchor = function() {
		$('a').click(function(event){
			var hash = $(this).prop("hash");
			var linkWithoutHash = $(this).prop("href").replace(/\#.*$/, '');
			var locationWithoutHash = window.location.href.replace(/\#.*$/, '');

			//if the link leads to this page and there is a hash part then scroll to element on same page
			if(linkWithoutHash === locationWithoutHash && /^#/.test(hash) === true) {
				event.preventDefault();
				history.pushState(null,null,hash);
				$('html,body').stop(true,false);
				$(hash).scrollToBySpeed({
					speed: 2000,
					offset: 200
				});
					/* do not run AJAX function */
			}
		});
	};
	var tolerancePx = 0;
	/**
	 * Note: For some reason, the id field is empty in the banner. In that case, get the id from the class attribute
	 */
	_checkVideoScroll = function(){
		// if ($('.size-checker.s').css('display') !== 'block'){
			var $videosToAutoplay = $('video.autoplay-enabled');
			var scrollTop = $(window).scrollTop() + tolerancePx;
			var scrollBottom = $(window).scrollTop() + $(window).height() - tolerancePx;
			$videosToAutoplay.each(function(i,e) {
				var yTopMedia = $(this).offset().top;
				var yBottomMedia = $(this).height() + yTopMedia;
				var id = e.attributes.id.nodeValue;
				var idFound = true;
				if (id === ""){
					var c = e.attributes.class.nodeValue;
					var offset = c.indexOf('video-');
					if (offset > -1){
						id = c.substr(offset);
					} else {
						idFound = false;
					}
				}
				if (idFound){
					id = id.replace('video-','');
					if(scrollTop < yBottomMedia && scrollBottom > yTopMedia){
						playVideo(id);
					} else {
						pauseVideo(id);
					}
				}
			});
		// }
	};
	videoOnScroll = function(){
		$(document).on('scroll', _checkVideoScroll);
		$(document).ready(function(){ _checkVideoScroll(); });
	};
})(jQuery);
