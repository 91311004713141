(function($) {
	openModal = function(id){
		window.animationHandle.stop();
		// $('body').addClass('no-scroll');
		// $('.flexible-content .atelier-modal-list #atelier-item-modal-'+id).fadeIn();
		openAtelier('#atelier-item-modal-'+id);
		setupAtelierModalCloseHandler();
		iosNoBounce('#atelier-item-modal-'+id);
	};
	/**
	 * On iOS touch devices (e.g. iPhones), when at the bottom of an atelier-item-modal
	 * starting a touch and move downwards, the menu bugs. This fix checks whether we
	 * are at the bottom and prevents going further down.
	 */
	iosNoBounce = function(selector){
		var $el = $(selector);
		var curY;
		$el.bind('touchstart', function(e) {
			if (!e.originalEvent.touches){ return; }
			curY = e.originalEvent.touches[0].pageY;
		});
		$el.bind('touchmove', function(e) {
			if (!e.originalEvent.touches){ return; }
			if (this.scrollTop >= this.scrollHeight - this.clientHeight){
				var prevY = curY;
				curY = e.originalEvent.touches[0].pageY;
				var deltaMove = curY - prevY;
				var goingDown = deltaMove < 0;
				if (goingDown){
					e.preventDefault();
				}
			}
		});
	};
	// closeModal = function(animationHandle){
	// 	$('.flexible-content .atelier-modal-list .atelier-item-modal').fadeOut();
	// 	$('body').removeClass('no-scroll');
	// 	animationHandle.start();
	// };
	atelierFlow = function(){
		var a = new Animation(10);
		a.init();
		window.animationHandle = a;
	};
	/**
	 * Clones the whole .atelier-list, puts it on top of the first, attaches css animation to
	 * both lists, making them slowly slide downwards. The css animation loops automatically,
	 * creating a seamless scrolling effect.
	 * @param float animationSpeed Scroll speed in px/s
	 */
	Animation = function(animationSpeed){
		this._animationSpeed = animationSpeed;
		this._items = { top:null, bottom:null, itemMaxHeight:null };
		this._createFiller = function(height){
			var $filler = $('<div class="atelier-filler"></div>');
			$('.flexible-content').append($filler);
			$('.flexible-content .atelier-filler').height(height);
		};
		this._getItemMaxHeight = function(){
			var maxH = 0;
			var $atelierItems = $('.atelier-list-bottom .atelier-item');
			for (var i = $atelierItems.length - 1; i >= 0; i--) {
				var h = $($atelierItems[i]).height();
				if (h > maxH){
					maxH = h;
				}
			}
			return maxH;
		};
		/**
		 * Injects keyframes and classes that use the keyframes
		 * @param  float height Height of an atelier list
		 */
		this._injectCss = function(height){
			var htmlToInject = '<style>{css}</style>';
			var theCss = '';
			var duration = height*1.0/this._animationSpeed;
			theCss += '@-webkit-keyframes move-top{ 0% { -webkit-transform:translateY(-'+height+'px); } 100% { -webkit-transform: translateY(0px); } }';
			theCss += '@-webkit-keyframes move-bottom{ 0% { -webkit-transform:translateY(0px); } 100% { transform: -webkit-translateY('+height+'px); } }';
			theCss += '@-moz-keyframes move-top{ 0% { -moz-transform:translateY(-'+height+'px); } 100% { -moz-transform: translateY(0px); } }';
			theCss += '@-moz-keyframes move-bottom{ 0% { -moz-transform:translateY(0px); } 100% { transform: -moz-translateY('+height+'px); } }';
			theCss += '@-ms-keyframes move-top{ 0% { -ms-transform:translateY(-'+height+'px); } 100% { -ms-transform: translateY(0px); } }';
			theCss += '@-ms-keyframes move-bottom{ 0% { -ms-transform:translateY(0px); } 100% { transform: -ms-translateY('+height+'px); } }';
			theCss += '@-o-keyframes move-top{ 0% { -o-transform:translateY(-'+height+'px); } 100% { -o-transform: translateY(0px); } }';
			theCss += '@-o-keyframes move-bottom{ 0% { -o-transform:translateY(0px); } 100% { transform: -o-translateY('+height+'px); } }';
			theCss += '@keyframes move-top{ 0% {transform:translateY(-'+height+'px); } 100% { transform: translateY(0); } }';
			theCss += '@keyframes move-bottom{ 0% {transform:translateY(0); } 100% { transform: translateY('+height+'px); } }';
			
			theCss += '.atelier-list-top{-webkit-animation:move-top '+duration+'s linear 0s infinite;}';
			theCss += '.atelier-list-bottom{-webkit-animation:move-bottom '+duration+'s linear 0s infinite;}';
			theCss += '.atelier-list-top{-moz-animation:move-top '+duration+'s linear 0s infinite;}';
			theCss += '.atelier-list-bottom{-moz-animation:move-bottom '+duration+'s linear 0s infinite;}';
			theCss += '.atelier-list-top{-ms-animation:move-top '+duration+'s linear 0s infinite;}';
			theCss += '.atelier-list-bottom{-ms-animation:move-bottom '+duration+'s linear 0s infinite;}';
			theCss += '.atelier-list-top{-o-animation:move-top '+duration+'s linear 0s infinite;}';
			theCss += '.atelier-list-bottom{-o-animation:move-bottom '+duration+'s linear 0s infinite;}';
			theCss += '.atelier-list-top{animation:move-top '+duration+'s linear 0s infinite;}';
			theCss += '.atelier-list-bottom{animation:move-bottom '+duration+'s linear 0s infinite;}';

			htmlToInject = htmlToInject.replace('{css}', theCss);
			$('head').append(htmlToInject);
		};
		this.init = function(){
			this._items.bottom = $('.flexible-content .atelier-list');
			var h = this._items.bottom.outerHeight();
			this._createFiller(h);
			this._injectCss(h);
			this._items.bottom.addClass('atelier-list-box');
			this._items.top = this._items.bottom.clone();
			this._items.bottom.addClass('atelier-list-bottom');
			this._items.top.addClass('atelier-list-top');
			$('.flexible-content .atelier').prepend(this._items.top);
			setupVideoClickHandler();
		};
		this.start = function(){
			$('.atelier-list-box').removeClass('atelier-list-paused');
		};
		this.stop = function(){
			$('.atelier-list-box').addClass('atelier-list-paused');
		};
		this.isRunning = function(){
			return !$('.atelier-list-box').hasClass('atelier-list-paused');
		};
		this.toggle = function(){
			if (this.isRunning()){
				this.stop();
			} else {
				this.start();
			}
		};
	};
})(jQuery);
