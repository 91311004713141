(function($) {
	var cookieName = 'mc4wp-form';
	var $signupForm = $('.mc4wp-form');
	_checkForErrors = function(){
		if ($signupForm.hasClass('mc4wp-form-error')){
			var $errorField = $('.mc4wp-response .mc4wp-error');
			var $input = $signupForm.find('input[type=email]');
			var errMsg = $errorField.text();
			if (errMsg.indexOf('valid email')){
				$signupForm.removeClass('mc4wp-form-error');
				$errorField.text('');
				$input.attr('placeholder', errMsg);
				$input.val('');
			}
			Cookies.set(cookieName, { error:1 }, { expires:365 });
		}
	};
	_initClickHandlers = function(){
		$('.mc4wp-form .close').click(function(e){
			$signupForm.fadeOut();
			Cookies.set('mc4wp-form', { closed:1 }, { expires:365 });
		});
	};
	initEmailSignup = function(){
		_initClickHandlers();
		_checkForErrors();
		var cookie = Cookies.getJSON('mc4wp-form');
		if (typeof cookie === 'undefined' || cookie.closed !== 1){
			$signupForm.fadeIn();
			$('.mc4wp-form input[type=submit]').click(function(e){
				e.preventDefault();
				Cookies.set('mc4wp-form', { closed:1 }, { expires:365});
				Cookies.set('mc4wp-form', { submitted:1 }, { expires:365});
				$signupForm.submit();
			});
		}
	};
})(jQuery);
