(function($) {
	var doHardClose = false;
	var doSetupDocumentKeypress = false;
	var SearchWindowFadeStyle = { NO_FADE:0, FADE_IN:1, FADE_OUT:2 };
	var setupSearchCloseHandler = function(){
		setupHamburgerClickHandler();
	};
	var setupTypeSearch = function(){
		// Open search window and search on type
		$(document).keypress(function(e){

			if (e.which === 13){
				e.preventDefault();
			}

			if (e.keyCode === 27) {
				return false;
			}

			$(document).off('keypress');
			doShowSearchWindow(SearchWindowFadeStyle.FADE_IN, function(){
				doHardClose = true;
				doSetupDocumentKeypress = true;
				setupSearchCloseHandler();
				$('#frmSearch [name=q]').val(e.key);
			});
		});

		$('input').keypress(function(e) { //Disable search toggle for input fields
		    e.stopPropagation();
		});
	};
	doCloseSearchWindow = function(){
		var $body       = $('body');
		var $searchOverlay    = $('#search-overlay');
		var $siteHeader = $('.site-header');
		$searchOverlay.fadeOut();
		$body.removeClass('search-open');
		if (doHardClose){
			doHardClose = false;
			$body.removeClass('overlay-open');
		}
		$('#mobile-nav .navigation').fadeIn();
		setupHamburgerClickHandler();
		if (doSetupDocumentKeypress){
			doSetupDocumentKeypress = false;
			setupTypeSearch();
		}
	};
	/**
	 * Show the search overlay
	 * @param SearchWindowFadeStyle fade Whether to fade or show instantly. Fade is used on search
	 *                                   icon click, instant is used on page reload
	 * @param func cb                    Called on fade finish
	 */
	doShowSearchWindow = function(fade, cb){
		var $body          = $('body');
		var $searchOverlay = $('#search-overlay');
		var $siteHeader    = $('.site-header');
		$body.addClass('overlay-open');
		var next = function(){
			$searchOverlay.find('.search').focus();
			if (cb){
				cb();
			}
		};
		if (fade === SearchWindowFadeStyle.NO_FADE){
			$body.addClass('search-open');
			$searchOverlay.show();
			next();
		} else if (fade === SearchWindowFadeStyle.FADE_IN){
			$searchOverlay.fadeIn(250, function(){
				$body.addClass('search-open');
				next();
			});
		} else {
			$('#mobile-nav .navigation').fadeOut(250, function(){
				$body.addClass('search-open');
				$searchOverlay.show();
				next();
			});
		}
	};
	/**
	 * 1. init vars
	 * 2. init event handlers
	 * 3. exec functions
	 *
	 * Note: the 'search' item in mobile menu should have no link attribute
	 * (i.e. no href, that's how jQuery targets it to fade in the search overlay)
	 */
	initSearch = function(){
		// Init vars //
		var SearchLengthCap = 3;
		var $searchOverlay = $('#search-overlay');
		var getUrlParam = function(paramName){
			var sPageURL = window.location.search.substring(1);
			var sURLVariables = sPageURL.split('&');
			for (var i = sURLVariables.length - 1; i >= 0; i--) {
				var sParameterName = sURLVariables[i].split('=');
				if (sParameterName[0] === paramName){
					return sParameterName[1];
				}
			}
		};
		// Event handlers //
		$('.site-header .web-menu .search-icon').click(function(e){
			doShowSearchWindow(SearchWindowFadeStyle.FADE_IN, function(){
				doHardClose = true;
				setupSearchCloseHandler();
			});
		});
		/**
		 * Click on "Search" in expanded menu
		 */
		$('#mobile-nav a:not([href])').click(function(e){
			e.preventDefault();
			doShowSearchWindow(SearchWindowFadeStyle.FADE_OUT, function(){
				setupSearchCloseHandler(false);
			});
		});
		$searchOverlay.find('.search').on('input', function(){
			var val = $(this).val();
			if (val.length < SearchLengthCap){ return; }
		});


		setupTypeSearch();

		// Exec functions on load //
		var populateSearchInput = function(queryParam){
			var searchTerm = getUrlParam(queryParam);
			searchTerm = decodeURIComponent(searchTerm);
			if (searchTerm !== ''){
				$searchOverlay.find('.search').val(searchTerm);
			}
			return searchTerm !== '';
		};
		if ($searchOverlay.hasClass('search-made')){
			doShowSearchWindow(SearchWindowFadeStyle.NO_FADE, function(){
				setupSearchCloseHandler(false);
			});
			populateSearchInput('q');
			setupAtelierModalClickHandlersInSearch();
		}
	};
	/**
	 * Enables ajax search. Disables ordinary form submit.
	 * @param int minCharsForSearch Sets lower limit of chars before search is performed. This is to prevent lots of search results.
	 * @param int searchDelayMs     Delay in ms before search is performed. This is to keep typing.
	 */
	ajaxSearch = function(){
		var minCharsForSearch = 2;
		var searchDelayMs = 500;
		var timerId = 0;
		var $form = $('#frmSearch');
		var $results = $("#search-overlay .results");
		$form.on('submit', function(e){
			e.preventDefault();
			return false;
		});
		var url = $form.attr('data-href');
		var $inputField = $('#frmSearchQ');
		$inputField.keyup(function(e){
			e.preventDefault();
			window.clearTimeout(timerId);
			if (e.currentTarget.value.length < minCharsForSearch){
				return false;
			}
			timerId = window.setTimeout(function(){
				$results.addClass('searching');
				var q = $inputField.val();
				$.ajax({
					url:url,
					type:'POST',
					data:'action=ajaxq&q='+q,
					success:function(res){
						$results.html(res);
						setupAtelierModalClickHandlersInSearch();
					},
					complete:function(){
						$results.removeClass('searching');
						$inputField.focus();
					}
				});
			}, searchDelayMs);
			return false;
		});
	};
})(jQuery);
