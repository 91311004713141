(function($) {
	isIOS = function(){
		if (!navigator){
			return false;
		}
		return (/iphone|ipad|ipod/i.test(navigator.userAgent.toLowerCase()));
	};
	/**
	 * Note: the second video element exists in atelier, because the .atelier gets cloned.
	 */
	toggleVideoPlayState = function(id){
		var $video = $('video#video-'+id);
		var $overlay = $('.overlay[dataId='+id+']');
		if (!$video.length){
			return;
		}
		if ($video[0].paused){
			$video[0].play();
			if ($video.length === 2){
				$video[1].muted = true;
				$video[1].play();
			}
			$overlay.removeClass('play');
		} else {
			$video[0].pause();
			if ($video.length === 2){
				$video[1].pause();
			}
			$overlay.addClass('play');
		}
	};
	playVideo = function(id){
		var $video = $('.flexible-content .video #video-'+id);
		var $overlay = $('.flexible-content .video [dataId='+id+']');
		if ($video.length && $video[0].paused){
			$video[0].play();
			if ($('body').hasClass('atelier') && $video.length === 2){
				console.log('second video play', $video);
				$video[1].muted = true;
				$video[1].play();
			}
			if (!isIOS()){
				$overlay.removeClass('play');
			}
		}
	};
	pauseVideo = function(id){
		var $video = $('.flexible-content .video #video-'+id);
		var $overlay = $('.flexible-content .video [dataId='+id+']');
		if ($video.length && !$video[0].paused){
			$video[0].pause();
			if ($('body').hasClass('atelier') && $video.length === 2){
				$video[1].pause();
			}
			$overlay.addClass('play');
		}
	};
	setupVideoClickHandler = function(){
		// 1. Click on video overlay to pause/resume video
		// 2. Disable autoplay on first click (tries to remove .autoplay-enabled, <video> may not have this class)
		$('.overlay').click(function(e){
			var id = e.currentTarget.attributes.dataId;
			if (id){
				toggleVideoPlayState(id.nodeValue);
				var $video = $('.video #video-'+id.nodeValue);
				$video.removeClass('autoplay-enabled');
				$(document).off('scroll');
				videoOnScroll();
			}
		});
	};
})(jQuery);
