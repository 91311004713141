(function($) {
	stickyHeader = function() {
		$(document).ready(function(){
			if ($(document).scrollTop() > 60) {
				$(".site-header").addClass("sticky");
			}
		});
		$(window).scroll(function() {
			if ($(document).scrollTop() > 60) {
				$(".site-header").addClass("sticky");
			} else {
				$(".site-header").removeClass("sticky");
			}
		});
	};
})(jQuery);
