(function($) {
	initWorkCarousel = function(){
		if ($('.carousel-container .slide').length > 1){
			var $slider = $('.carousel-container');
			$slider.slick({
				lazyLoad: 'progressive',
				slidesToShow: 1,
				slidesToScroll: 1,
				autoplay: true,
				autoplaySpeed: 4000,
				dots: true,
				draggable:false,
				arrows: false,
				prevArrow: '<div class="arrow-overlay arrow-overlay-left"><button type="button" class="slick-prev"></button></div>',
				nextArrow: '<div class="arrow-overlay arrow-overlay-right"><button type="button" class="slick-next"></button></div>',
				adaptiveHeight: true,
				useTransform: false,
				pauseOnFocus: false,
				pauseOnHover: false
			});
			$slider.on('afterChange', function(slick, currentSlide, prevSlide){
				var i = currentSlide.currentSlide;
				var $video = $(currentSlide.$slides[i]).find('video.autoplay-enabled');
				if ($video.length && $video[0].paused){
					$video[0].play();
				}
			});
			var $videoInFirstSlide = $('.slick-slider .slick-current video.autoplay-enabled');
			var $overlayFirstSlide = $('.slick-slider .slick-current .overlay.play');
			if ($videoInFirstSlide.length){
				$videoInFirstSlide[0].play();
				$overlayFirstSlide.removeClass('play');
			}
		}
	};
})(jQuery);
