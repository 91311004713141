(function($) {
  stopAtelierModalVideo = function(){
    var $overlay = $('.flexible-content .atelier-item-modal .overlay');
    var $video = $('.flexible-content .atelier-item-modal video');
    if ($video.length){
      $video[0].pause();
      if ($overlay.length){
        $overlay.addClass('play');
      }
    }
  };
  closeAtelier = function(e, cb){
    $('.atelier-item-modal').fadeOut();
    if (cb){
      cb();
    }
  };
  openAtelier = function(target){
    var $target = $(target);
    if ($target.length){
      $target.fadeIn();
      $('body').addClass('overlay-open');
      $('body').addClass('atelier-open');
    }
  };
  atelierModal = function(){
    $(document).ready(function(){
      setupAtelierModalClickHandlers();
      var hash = window.location.hash;
      if (hash !== ""){
        openAtelier(hash);
        setupAtelierModalCloseHandler();
      }
    });
  };
  var _atelierItemClickHandler = function(e){
    if (e.attributes && e.attributes.href && e.attributes.href.nodeValue){
      var hashId = e.attributes.href.nodeValue;
      if (hashId[0] === '#'){
        $e = $(e);
        $e.removeAttr('href');
        $e.attr('dataHref', hashId);
        $e.click(function(event){
          openAtelier(hashId);
          setupAtelierModalCloseHandler();
        });
      }
    }
  };
  /**
   * All links in search grid that display a modal (== start with #) should open
   * the modal. Prevent the modal from closing the search screen.
   */
  setupAtelierModalClickHandlersInSearch = function(){
    $('.grid-item a, .results a').each(function(i,e){
      _atelierItemClickHandler(e);
    });
  };
  setupAtelierModalClickHandlers = function(){
    $('.open-atelier').each(function(i,e){
      _atelierItemClickHandler(e);
    });
  };
  setupAtelierModalCloseHandler = function(){
    setupHamburgerClickHandler();
  };
})(jQuery);
